// https://auth0.com/blog/authenticating-svelte-apps/

import { derived, writable } from "svelte/store";

// メールアドレスの確認は一旦検証しない
// interface User {
//   email?: string;
//   email_verified?: boolean;
// }

// export const user = writable({} as User);
// export const isAuthenticated = derived(user, u => !!u && !!u.email);
// export const isAuthenticatedAndVerified = derived(user, u => !!u && !!u.email_verified);
export const isAuthenticated = writable(false);
export const error = writable();
