// Based on https://auth0.com/blog/authenticating-svelte-apps/
// Docs: https://auth0.github.io/auth0-spa-js/index.html

import { createAuth0Client, type Auth0Client, type RedirectLoginOptions } from "@auth0/auth0-spa-js";
import { isAuthenticated } from "./store";
import config from "./config";

async function createClient(): Promise<Auth0Client> {
  return createAuth0Client({
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams: {
      audience: config.audience,
      scope: "profile email company_reviews",
    },
    cacheLocation: "localstorage",
    useRefreshTokens: true,
  });
}

async function loginWithRedirect(client: Auth0Client, options?: RedirectLoginOptions): Promise<void> {
  try {
    await client.loginWithRedirect(options)
    try {
      await client.getTokenSilently();
      isAuthenticated.set(true);
    } catch (error) {
      if (error.error !== 'missing_refresh_token' && error.error !== 'login_required') {
        throw error;
      }
    }
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
}

async function handleRedirectCallback(client: Auth0Client) {
  return client.handleRedirectCallback()
}

function logout(client: Auth0Client, returnTo?: string): void {
  client.logout({
    logoutParams: {
      returnTo,
    },
  });
}

const auth = {
  createClient,
  loginWithRedirect,
  handleRedirectCallback,
  logout,
};

export default auth;
